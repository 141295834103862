import { ShortcutCountryListStyle } from '../../styles/components/home';

export interface IShortcutCountryList {
  title: string;
  description: string;
  showAllLabel: string;
  regions: {
    name: string;
    link: string;
    countries: {
      name: string;
      link: string;
    }[];
  }[];
}

const ShortcutCountryList = (props: IShortcutCountryList) => {
  return (
    <div className={ShortcutCountryListStyle.shortcutCountryList}>
      <div className={ShortcutCountryListStyle.info}>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
      <div className={ShortcutCountryListStyle.regionWrap}>
        {props.regions.map((region, index) => (
          <div className={ShortcutCountryListStyle['regionsContainer']} key={index}>
            <div className={ShortcutCountryListStyle['regions']}>
              <h3>{region.name}</h3>
              <div className={ShortcutCountryListStyle['countries']}>
                {region.countries.map((country, index) => (
                  <span key={index}>
                    <a href={country.link}>
                      {country.name}
                      {index === region.countries.length - 1 ? '' : ','}
                    </a>
                  </span>
                ))}
                {''}
                {'...'}
                <span className={ShortcutCountryListStyle.countriesMore}>
                  <a href={region.link}>{props.showAllLabel}</a>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShortcutCountryList;

