import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { HeroStyle } from '../../styles/components/home';
import { Button, HighlightedTitle } from '../common/ui';

export interface IHeroComponentProps {
  title: string;
  description: string;
  buttonLabel?: string;
  buttonLink?: string;
  buttonStyle?: 'primary' | 'secondary' | 'minimal' | 'minimal-secondary' | 'minimal-small' | 'cta' | 'tertiary' | 'outline' | 'outline-small' | 'subtle-small';
  backgroundUrl: string;
  titleTagName?: string;
  titleHighlightedText?: string | undefined;
}

const Hero = (props: IHeroComponentProps) => {
  const router = useRouter();
  const Tag = props.titleTagName || 'h1';
  const getTitle = (title: string, titleHighlightedText?: string) => {
    return !!titleHighlightedText ? <HighlightedTitle text={title} highlightedText={titleHighlightedText} headingTagName={props.titleTagName} /> : React.createElement(Tag, {}, title);
  };

  return (
    <>
      <div className={HeroStyle.hero}>
        <div className={HeroStyle['contentLeft']}>
          <div className={HeroStyle['text']}>
            {getTitle(props.title, props.titleHighlightedText)}
            <ReactMarkdown>{props.description}</ReactMarkdown>
          </div>
          {!!props.buttonLink && (
            <div className={HeroStyle['buttonWrapper']}>
              <Button disabled={false} color={props.buttonStyle || 'primary'} onClick={() => router.push(props.buttonLink as string)}>
                {props.buttonLabel}
              </Button>
            </div>
          )}
        </div>
        <div className={HeroStyle['contentRight']}>
          <div className={HeroStyle['image-container']}>
            <Image alt={'heroimage'} src={props.backgroundUrl} priority fill sizes="(max-width: 768px) 100vw, 25vw" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
