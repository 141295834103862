import Image from 'next/image';
import { useRouter } from 'next/router';
import { FeaturedServicesStyle } from '../../styles/components/home';

export interface IFeaturedServiceProps {
  subTitle: string;
  title: string;
  description: string;
  services: { title: string; description: string; iconPath: string; link: string }[];
}

const FeaturedServices = (props: IFeaturedServiceProps) => {
  const router = useRouter();
  return (
    <div className={FeaturedServicesStyle.services}>
      <div className={FeaturedServicesStyle['services-info']}>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
      <div className={FeaturedServicesStyle['services-items']}>
        {props.services.map((service, index) => {
          return (
            <div key={index} className={FeaturedServicesStyle['services-item']} onClick={() => router.push(service.link)}>
              {/* <div className={FeaturedServicesStyle['services-item-icon']}>{service.iconPath}</div> */}
              <Image
                className={FeaturedServicesStyle['services-item-icon']}
                src={service.iconPath}
                width={55}
                height={55}
                alt={service.title}
              />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedServices;
